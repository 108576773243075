<template>
  <v-footer
    tile
    height="80"
    class="font-weight-medium shadow-sm mx-1 my-4 rounded-lg"
    :color="$vuetify.theme.dark ? 'dark' : 'secondary'"
    :dark="$vuetify.theme.dark"
    :inset="true"
  >
    <v-col
      class="text-left"
      cols="12"
    >
      <div class="d-flex flex-row justify-space-between align-center white--text">
        <div>
          Servercentralen Dashboard
        </div>
        <div>
          <strong class="white--text">
            © Created By 2021-{{ new Date().getFullYear() }}
          </strong>
        </div>
      </div>
    </v-col>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>
